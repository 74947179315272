<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Reading & Writing：Fill in the blanks
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Reading & Writing：Fill in the blanks</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You'll get text with some words missing. Click on each blank, a list of choice will appear. Select the appropriate answer choice for each blank.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading and writing</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 5 - 6 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>Each blank will have a drop-down options filled with several words. You need to select the word that you find most appropriate.</li>
                <li>Read before and after the blank to help you identify the correct word.</li>
                <li>Skim for overall meaning to help you choose the correct word.</li>
                <li>Good vocabulary and synonyms can help to choose the best answers.</li>
            </ul>
            </template>
            <app-ReadingExtraInfo />
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Reading & Writing：Fill in the blanks</h4>
            <p class="questHeading">There are some words missing in the following text. Please select the correct word in the drop-down box.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Time </p><app-Timer ref="timer" :increment="true"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblrdfillinblanksq'"/>
            </div>
            </div>
            <p class="questdesc" v-html="selectedquestion.question_para"></p>
            <div class="row">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="this.userAnswer.answers">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:<span class="pl-1">{{score_count}}</span>/{{correctAnswer.length}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.option_desc}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12 mb-2 mt-2 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your result is {{this.userAnswer.user_score}} out of {{correctAnswer.length}}</strong></p>
                </div>
                <div class="col-12" v-if="correctAnswer != null">
                    <ul >
                        <li v-for="item in correctAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.option_desc}}</strong></li>
                    </ul>
                </div>
                <div class="col-12 mt-4 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>

                </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tbluserrdfillinblanksanswer'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ReadingExtraInfo from '../reuseable/readingInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService'
export default {
    components: {
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-dictTemp': dictTemplate,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab,
        'app-ReadingExtraInfo':ReadingExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:null,
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score: '',
                mock_test_id:0
            },
            score_count:0,
            options:[],
            checkAnswerFlag: false,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
            modelTitle:'heading',
            dictText:''
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        changeText(){
            //considering less than 20 fill in balnks
            for(var i =1;i<20;i++){
            var replaceVariable='#blank'+i+'#'
            this.selectedquestion.question_para = this.selectedquestion.question_para.replaceAll(replaceVariable,'<span id="select'+i+'"></span>')
            }
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        showModal() {
            for(var index = 1; index <= 20; index++){
               this.dictText = this.dictText.replace('#blank' + index + '#', '')
            }
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        reset(){
            this.checkAnswerFlag = false;
            this.score_count = 0;
            this.correctAnswer =  null
            this.answerLists = null
            this.selectedquestion =null
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                question_id:0,
                answers:null,
                url:'',
                user_score: '',
                mock_test_id:0
            }
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            if(this.currentQuestion==questionId){
                return $('select').each(function(index,item){
                if(item.options.selectedIndex >0){
                    item.options.selectedIndex = -1;
                }
            });
            }
            this.currentQuestion=questionId
            this.removeClasses()
            this.reset()
            this.userAnswer.question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            this.dictText=this.selectedquestion.question_para
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            //console.log(this.selectedquestion)
            this.modelTitle = this.selectedquestion.title
            this.changeText()
            this.questionservice('configfillingblanksqrw/id='+questionId).then(()=>{
            this.answerLists = this.getQuestionList().data
            //console.log(this.answerLists)
            }).then(()=>{
                var loopEnd = false
                var iteration = 1
                while(!loopEnd){
                    const currentOPtions = this.answerLists.filter(function (item) {
                    return item.blank_id == iteration;
                    })
                    //console.log('currentOPtions')
                    //console.log(currentOPtions)
                     var selectList = "<select style='margin:0px 5px;' name='numbers' id='dropdown"+iteration+"'>";
                        if(currentOPtions.length<=0)
                        {
                            loopEnd = true
                        }
                        else{
                            selectList += '<option value="0"></option>';
                            $.each(currentOPtions, function (i) {
                                const value= currentOPtions[i].option_id
                                const text = currentOPtions[i].option_desc
                                 selectList += '<option value="'+value+'">'+text+'</option>';
                            });
                            selectList += "</select>";
                            //console.log($('#select'+iteration))
                            $('#select'+iteration).append(selectList)
                        }
                    iteration++
                }
                this.actualAnswer= this.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
                    })
                this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(0)
                })
            })
        },
        async checkAnswer(){
            var vm = this;
            this.checkAnswerFlag = true;
            this.score_count = 0;
            vm.removeClasses()
            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            })
            const correct = vm.correctAnswer
            //console.log(correct)
            $('select').each(function(index,item){
                //console.log(item.options.selectedIndex)
                //console.log(item.options[item.options.selectedIndex].text)
               
                if(item.options.selectedIndex >0){
                    const itemText = item.options[item.options.selectedIndex].text
                    if(correct[index].option_desc != itemText)
                    {
                        $(item).addClass('wrongSelection wrongAnswer')
                    }
                    else{
                        vm.score_count++;
                        $(item).addClass('rightSection rightAnswer')
                    }
                     const tempCt= {
                        answer_desc: itemText
                    }
                    vm.options.push(tempCt);
                }
                else{
                    const tempCt= {
                        answer_desc: 'Not Selected'
                    }
                    vm.options.push(tempCt);
                     $(item).addClass('wrongSelection')
                }
            })

            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.correctAnswer.length ;

        },
        removeClasses(){
            $('div.wrongSelection').removeClass('wrongSelection wrongAnswer')
            $('div.rightSection').removeClass('rightSection rightAnswer')
        },
        timerEnd(){
            //console.log("end of time")
        },
        async submitanswer(){
            var vm = this;
            this.userAnswer.user_id=this.$ls.get('user').user_id
            await vm.checkAnswer();
            // $('select').each(function(index,item){
            //     if(item.options.selectedIndex >0){
            //         const tempCt= {
            //             answer_desc: item.options[item.options.selectedIndex].text
            //         }
            //         vm.options.push(tempCt);
            //     }
            // });
            this.userAnswer.mock_test_id=0
            this.userAnswer.url = 'userrwblankanswers/createblankanswer';
            this.userAnswer.answers = this.options;
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
                
            
        },
        resetAll(){
            $('select').each(function(index,item){
                if(item.options.selectedIndex >0){
                    item.options.selectedIndex = -1;
                }
            });
            //this.options=[]
            this.score_count = 0;
            this.checkAnswerFlag = false;
            this.userAnswer = {
                question_id:0,
                answers:null,
                url:'',
                user_score: ''
            }
            this.actualAnswer=null
        }
    },
    beforeMount(){
        this.questionservice('configfillingblanksqrw/getallfillinblanks').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        })
    }
}
</script>
<style scoped>
p{
line-height: 25px;
}
.text-center button{
    margin-right:30px
}
li.rightAnswer
{
    list-style: decimal;
}
.questdesc
{
    line-height: 30px;
background: none;
border-style: solid;
border-color: #ccc;
border-radius: 3px;
margin-top: 5px;
line-height: 35px!important;
}

</style>